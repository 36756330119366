import {BasicObject} from '@/types';
import {getAdjustAttribution} from '@/utils/adjust';
import {packageId} from '@/config';
import {trackEndponit} from '@/app.service';
import {getUserVersion} from '@/utils';

interface TrackEvent {
  type: 'track';
  distinct_id: string;
  project: number;
  event: 'expose' | 'click';
  time: number;
  properties: BasicObject;
  anonymous_id: string;
  login_id: string | null;
}

const _events: TrackEvent[] = [];
let timer: NodeJS.Timeout | null = null;
const REPORT_INTERVAL = 5000; // 5秒
const QUEUE_THRESHOLD = 20; // 队列阈值

export const trackRequest = async (data: TrackEvent[]): Promise<void> => {
  try {
    await trackEndponit(data);
    return Promise.resolve();
  } catch (e) {
    console.error('report failed', e);
    return Promise.reject(e);
  }
};

const sendEvents = async () => {
  if (_events.length === 0) {
    return;
  }
  const reportEvent: TrackEvent[] = [];
  try {
    reportEvent.splice(0, 0, ..._events);
    _events.length = 0;
    // await trackRequest(reportEvent);
  } catch (e) {
    _events.splice(_events.length, 0, ...reportEvent);
    console.error('report failed', e);
  } finally {
    if (timer !== null) {
      clearTimeout(timer);
      timer = null;
    }
  }
};

function scheduleReport() {
  if (timer === null) {
    timer = setTimeout(sendEvents, REPORT_INTERVAL);
  }
}

const properties: BasicObject = {
  $url_path: window?.location?.pathname || '',
  $referrer: getUserVersion() ? 'A_HOME' : 'B_HOME',
};

const report = (event: 'expose' | 'click', data: BasicObject) => {
  const distinct_id = getAdjustAttribution()?.adid || 'unknown';

  const baseProps = {
    eventKey: data.event,
    eventValue: data.event,
  };

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const login_id = user.userId;

  const trackEvent: TrackEvent = {
    type: 'track',
    distinct_id,
    project: packageId,
    event,
    time: Date.now(),
    properties: {...baseProps, ...properties},
    anonymous_id: login_id || distinct_id,
    login_id: login_id || null,
  };

  _events.push(trackEvent);

  if (_events.length >= QUEUE_THRESHOLD) {
    // 如果队列长度达到阈值，立即发送并取消定时器
    if (timer !== null) {
      clearTimeout(timer);
      timer = null;
    }
    sendEvents();
  } else {
    // 否则，安排定时发送
    scheduleReport();
  }
};

// 确保在页面卸载时发送剩余的事件
if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', () => {
    if (_events.length > 0) {
      sendEvents();
    }
  });
}

export const trackExpose = (event: string, reportInfo: BasicObject = {}) => {
  report('expose', {event, ...reportInfo});
};

export const trackClick = (event: string, reportInfo: BasicObject = {}) => {
  report('click', {event, ...reportInfo});
};
