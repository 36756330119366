import React from 'react';
import {toPriceStr} from '@/utils';
import {useTranslation} from 'react-i18next';

export function UserPlay({number}: {number: number}) {
  const i18n = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        paddingRight: '0.75rem',
        alignItems: 'center',
        gap: '0.25rem',
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none">
        <path
          d="M5.68359 8.02954C4.86875 8.02954 4.10312 7.71235 3.52754 7.13677C2.95195 6.56118 2.63477 5.79419 2.63477 4.98071C2.63477 4.16724 2.95195 3.40024 3.52754 2.82466C4.10312 2.24907 4.87012 1.93188 5.68359 1.93188C6.49707 1.93188 7.26406 2.24907 7.83965 2.82466C8.41523 3.40024 8.73242 4.16724 8.73242 4.98071C8.73242 5.79419 8.41523 6.56118 7.83965 7.13677C7.26406 7.71235 6.49707 8.02954 5.68359 8.02954ZM5.68359 2.74946C4.45449 2.74946 3.45371 3.75024 3.45371 4.97935C3.45371 6.20845 4.45449 7.20923 5.68359 7.20923C6.9127 7.20923 7.91348 6.20845 7.91348 4.97935C7.91211 3.75024 6.9127 2.74946 5.68359 2.74946Z"
          fill="#8D97AB"
        />
        <path
          d="M10.4936 12.019H9.67324C9.67324 10.954 9.25762 9.95181 8.5043 9.19849C7.75098 8.44517 6.74883 8.02954 5.68379 8.02954C4.61875 8.02954 3.6166 8.44517 2.86328 9.19849C2.10996 9.95181 1.69434 10.954 1.69434 12.019H0.874023C0.874023 11.3696 1.00117 10.7393 1.25273 10.1473C1.49473 9.57446 1.84199 9.0604 2.28359 8.6188C2.7252 8.1772 3.23926 7.82993 3.81211 7.58794C4.40547 7.33638 5.03574 7.20923 5.68379 7.20923C6.33184 7.20923 6.96348 7.33638 7.55547 7.58794C8.12832 7.82993 8.64238 8.1772 9.08399 8.6188C9.52559 9.0604 9.87285 9.57446 10.1148 10.1473C10.3664 10.7393 10.4936 11.3696 10.4936 12.019Z"
          fill="#8D97AB"
        />
        <path
          d="M9.76934 7.10811L9.41113 6.36982C10.1152 6.02666 10.5541 5.37998 10.5541 4.68271C10.5541 3.61631 9.55332 2.74951 8.32422 2.74951V1.9292C9.12949 1.9292 9.88965 2.20947 10.4625 2.71943C11.0504 3.24033 11.373 3.93896 11.373 4.68271C11.373 5.69717 10.7592 6.62686 9.76934 7.10811Z"
          fill="#8D97AB"
        />
        <path
          d="M13.1324 11.1384H12.3121C12.3121 10.1759 11.9854 9.24209 11.3934 8.50928C10.8109 7.79014 10.007 7.30342 9.12793 7.14209L9.27695 6.33545C10.3488 6.53369 11.3264 7.12295 12.0318 7.99385C12.7414 8.87158 13.1324 9.98857 13.1324 11.1384Z"
          fill="#8D97AB"
        />
      </svg>
      <span
        style={{
          color: '#31373D',
          fontFamily: 'Inter-Bold',
          fontSize: '0.625rem',
          fontWeight: 700,
          letterSpacing: '-0.3px',
          textTransform: 'capitalize',
        }}>{`${toPriceStr(number, {fixed: 0, showCurrency: false})} ${i18n.t(
        'home.label.userPlay',
      )}`}</span>
    </div>
  );
}
