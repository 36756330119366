import React, {useEffect} from 'react';
import {BasicObject} from '@/types';
import {trackExpose} from './utils';

interface TrackWrapProps {
  children: React.ReactElement;
  message: string;
  reportInfo?: BasicObject;
}

const ExposureWrap: React.FC<TrackWrapProps> = ({
  children,
  message,
  reportInfo = {},
}) => {
  const handleIntersect = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      trackExpose(message, reportInfo);
    }
  };

  useEffect(() => {
    if (!message) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach(handleIntersect);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      },
    );

    const curDom = document.querySelector(`.${message}`)?.nextElementSibling;
    if (curDom) {
      observer.observe(curDom);
    }

    return () => {
      if (curDom) {
        observer.unobserve(curDom);
      }
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* 绝对定位空元素防止影响样式，使用此元素来获取子元素dom */}
      <div style={{position: 'absolute'}} className={message} />
      {children}
    </>
  );
};

export default ExposureWrap;
